import { Component, Mixins, Vue, Watch } from "vue-property-decorator";
import AnalysisBase from "@/models/analysis/analysis-base";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import Flash, { ErrorAlert } from "@/store/common/flash";
import ShopGet from "@/store/shop/get";
import StampChart from "@/views/analysis/StampChart.vue";
import Admin from "@/store/admin/admin";
import { isSuccess } from "@/api/response";
import { saveAs } from "file-saver";
import StampCommonGet from "@/store/analysis/stamp-common/get";
import StampGet from "@/store/analysis/stamp/get";
import { StampCommonGetRequest } from "@/api/analysis/stamp-common/request";
import { StampGetRequest } from "@/api/analysis/stamp/request";
import Stamp from "@/components/dashboard/Stamp.vue";
import StampProfileGet from "@/store/analysis/stamp-profile/get";

interface StampCommonInputOptions {
  startDate: string;
  endDate: string;
  viewType: number;
  selectShop: { id: string; name: string } | null;
}

export interface AttributeSelections {
  label: string;
  value: number;
  isSelected: boolean;
  isEnabled: boolean;
}

@Component({
  components: {
    UlContentHeader,
    UIDatePicker,
    StampChart
  }
})
export default class AnalysisStamp extends Mixins(AnalysisBase) {
  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "スタンプ";
  headingSub = "Stamp";
  breadCrumbs = [{ text: "スタンプ", disabled: true }];

  // ------------
  // 変動値
  // ------------
  // ローディングステータス
  isLoadedShopData = false;
  isLoadedStampCommonData = false;
  isLoadedStampData = false;
  isLoadedStampProfileData = false;

  viewTypes = [
    { label: "日別", value: 1 },
    { label: "月別", value: 2 }
  ];

  // DatePickerの表示フラグ
  startDateModal = null;
  endDateModal = null;

  // 検索入力オプション
  stampCommonInputOptions: StampCommonInputOptions = {
    startDate: this.initDates[0],
    endDate: this.initDates[1],
    viewType: this.viewTypes[0].value,
    selectShop: null
  };

  stampInputOptions: StampCommonInputOptions = {
    startDate: this.initDates[0],
    endDate: this.initDates[1],
    viewType: this.viewTypes[0].value,
    selectShop: null
  };

  totalCountOnDrillDowned = 0;
  totalCountSuffix = "個";

  get startDateForView() {
    return this.replaceHyphenToSlash(this.stampCommonInputOptions.startDate);
  }
  set startDateForView(date) {
    this.stampCommonInputOptions.startDate = this.replaceSlashToHyphen(date);
  }

  get endDateForView() {
    return this.replaceHyphenToSlash(this.stampCommonInputOptions.endDate);
  }
  set endDateForView(date) {
    this.stampCommonInputOptions.endDate = this.replaceSlashToHyphen(date);
  }

  get shopItems() {
    return ShopGet.getItems;
  }

  get initDates() {
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    const firstDate = new Date(y, m, 1);
    const lastDate = new Date(y, m + 1, 0);
    return [this.stringFromDate(firstDate), this.stringFromDate(lastDate)];
  }

  /**
   * createdライフサイクルフック
   */
  async created() {
    if (this.editFocused || !this.isNeededActiveOnDashBoard) {
      return false;
    }

    await this.fetchShopList();
    if (!Admin.isAdmin) {
      this.stampCommonInputOptions.selectShop = ShopGet.getItems[0];
      await this.fetchStampCommon();
      if (StampCommonGet.getData && StampCommonGet.getData.datasets) {
        await this.fetchStamp();
      }
    }
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await ShopGet.clearResponse();
    await StampCommonGet.clearResponse();
    await StampGet.clearResponse();
    await StampProfileGet.setSelectedAttributes([]);
  }

  /**
   * 総件数
   */
  get totalCount() {
    return this.totalCountOnDrillDowned > 0
      ? this.totalCountOnDrillDowned
      : StampCommonGet.getTotalCount;
  }

  get stampCommonData() {
    return StampCommonGet.getData;
  }

  get stampGrantData() {
    return StampGet.getGrantData;
  }

  get stampTicketData() {
    return StampGet.getTicketData;
  }

  get stampCardData() {
    return StampGet.getCardData;
  }

  get mode() {
    return StampCommonGet.getMode;
  }

  /**
   * 店舗情報の一覧を取得する処理
   */
  async fetchShopList(): Promise<boolean> {
    this.isLoadedShopData = false;
    await ShopGet.get();
    if (!ShopGet.isSuccess) {
      await Flash.setErrorNow({
        message: ShopGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedShopData = true;
    return ShopGet.isSuccess;
  }

  /**
   * グラフデータを取得する処理
   */
  async fetchStamps() {
    this.totalCountSuffix = "個";
    this.totalCountOnDrillDowned = 0;
    await this.fetchStampCommon();
    if (StampCommonGet.getData && StampCommonGet.getData.datasets) {
      await this.fetchStamp();
    }
  }

  async fetchStampCommon(): Promise<boolean> {
    this.isLoadedStampCommonData = false;
    await Flash.clear();
    const stampCommonGetRequest: StampCommonGetRequest = {
      startDate: this.startDateForView,
      endDate: this.endDateForView,
      searchShopId:
        this.stampCommonInputOptions.selectShop != null
          ? Number(this.stampCommonInputOptions.selectShop.id)
          : null,
      viewType: this.stampCommonInputOptions.viewType
    };
    await StampCommonGet.get(stampCommonGetRequest);
    if (!StampCommonGet.isSuccess) {
      await Flash.setErrorNow({
        message: StampCommonGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedStampCommonData = true;
    return StampCommonGet.isSuccess;
  }

  async fetchStamp(): Promise<boolean> {
    this.isLoadedStampData = false;
    await Flash.clear();
    const stampGetRequest: StampGetRequest = {
      startDate: this.startDateForView,
      endDate: this.endDateForView,
      searchShopId:
        this.stampCommonInputOptions.selectShop != null
          ? Number(this.stampCommonInputOptions.selectShop.id)
          : null,
      viewType: this.stampCommonInputOptions.viewType,
      mode: StampCommonGet.getMode
    };
    await StampGet.get(stampGetRequest);
    if (!StampGet.isSuccess) {
      await Flash.setErrorNow({
        message: StampGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedStampData = true;
    return StampGet.isSuccess;
  }

  updateTotalCountOnDrillDowned(object: {
    totalCount: number;
    suffix: string;
  }) {
    this.totalCountOnDrillDowned = object.totalCount;
    this.totalCountSuffix = object.suffix;
  }
}

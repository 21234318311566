import service from "@/api/service";
import { StampProfileGetRequest } from "@/api/analysis/stamp-profile/request";
import { StampProfileGetResponse } from "@/api/analysis/stamp-profile/response";

/**
 * スタンププロフィール属性取得APIをコールします。
 *
 * @param getRequest スタンププロフィール属性取得APIのリクエストボディ
 * @return StampProfileGetResponse
 */
export async function get(getRequest: StampProfileGetRequest) {
  const response = await service.post("/analysis-stamp-profile", getRequest);
  return response.data as StampProfileGetResponse;
}

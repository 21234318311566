import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import * as StampProfileAPI from "@/api/analysis/stamp-profile";
import { isSuccess } from "@/api/response";
import { StampProfileGetRequest } from "@/api/analysis/stamp-profile/request";
import {
  StampProfileGetResponse,
  StampProfileRankingGraphData,
  StampProfileTopGraphData
} from "@/api/analysis/stamp-profile/response";
import { AttributeSelections } from "@/models/analysis/stamp";

const MODULE_NAME = "analysis/stamp-profile/get";

/**
 *  スタンププロフィール属性取得API（/analysis-stamp-profile）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getRequest: StampProfileGetRequest = {} as StampProfileGetRequest;
  getResponse: StampProfileGetResponse = {} as StampProfileGetResponse;
  selectedAttributes: AttributeSelections[] = [];

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getResults() {
    if (this.getResponse.results) {
      return this.getResponse.results;
    } else {
      return [];
    }
  }

  get getRankingData() {
    if (this.getResponse.results) {
      return this.getResponse.results.rankingData.data;
    } else {
      return {} as StampProfileRankingGraphData;
    }
  }

  get getTopData() {
    if (this.getResponse.results) {
      return this.getResponse.results.topData.data;
    } else {
      return {} as StampProfileTopGraphData;
    }
  }

  get getLabelsAttribute() {
    if (this.getResponse.results) {
      return this.getResponse.results.labelsAttribute;
    } else {
      return [];
    }
  }

  get getTicketId() {
    if (this.getResponse.results) {
      return this.getResponse.results.ticketId;
    } else {
      return null;
    }
  }

  get getGetRequest() {
    return this.getRequest;
  }

  get getSelectedAttributes() {
    return this.selectedAttributes;
  }

  // MutationActions
  @MutationAction
  async get(getRequest: StampProfileGetRequest) {
    const getResponse = await StampProfileAPI.get(getRequest);
    return {
      getResponse,
      getRequest
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as StampProfileGetResponse
    };
  }

  @MutationAction
  async setSelectedAttributes(selectedAttributes: AttributeSelections[]) {
    return {
      selectedAttributes
    };
  }
}

export default getModule(Get);

import service from "@/api/service";
import { StampUserCSVGetRequest } from "@/api/analysis/stamp-user-csv/request";
import { DownloadCSVResponse } from "@/api/response";

/**
 * スタンプユーザーCSV出力APIをコールします。
 *
 * @param getRequest スタンプユーザーCSV出力APIのリクエストボディ
 * @return response
 */
export async function get(getRequest: StampUserCSVGetRequest) {
  const response = await service.postReceiveCSV(
    "/analysis-stamp-user-csv",
    getRequest
  );
  return response as DownloadCSVResponse;
}

import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import * as StampCommonAPI from "@/api/analysis/stamp-common";
import { isSuccess } from "@/api/response";
import { StampCommonGetRequest } from "@/api/analysis/stamp-common/request";
import { StampCommonGetResponse } from "@/api/analysis/stamp-common/response";
import { AnalysisGraphData } from "@/api/analysis/common/response";

const MODULE_NAME = "analysis/stamp-common/get";

/**
 *  スタンプ付与数共通取得API（/analysis-stamp-common）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getRequest: StampCommonGetRequest = {} as StampCommonGetRequest;
  getResponse: StampCommonGetResponse = {} as StampCommonGetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getData() {
    if (this.getResponse.results) {
      return this.getResponse.results.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getTotalCount() {
    if (this.getResponse.results) {
      return this.getResponse.results.totalCount || 0;
    } else {
      return 0;
    }
  }

  get getMode() {
    if (this.getResponse.results) {
      return this.getResponse.results.mode;
    } else {
      return 1;
    }
  }

  get getGetRequest() {
    return this.getRequest;
  }
  // MutationActions
  @MutationAction
  async get(getRequest: StampCommonGetRequest) {
    const getResponse = await StampCommonAPI.get(getRequest);
    return {
      getResponse,
      getRequest
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as StampCommonGetResponse
    };
  }
}

export default getModule(Get);
